import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, Input , Button, Dialog, Tip } from 'tyb';
import queryString from 'query-string';
import { NewSelect } from 'ucode-components';
const { deleteMemberShip, exportMemberShip, exportAvailable } = iceStarkData.store.get('commonAction')?.memberManage;


const { domainConfigsByCodeBank } = iceStarkData.store.get('commonAction')?.domainConfigs;
const { loadEnterprisesSelections } = iceStarkData.store.get('commonAction')?.enterprises


// const { loadAddressBookById } = iceStarkData.store.get('commonAction')?.addressBooks;
import { formatDate } from 'ucode-utils';


import enums from '@/assets/enum.json';
import { handSortOrder } from 'ucode-utils';
import { ConfirmPopout } from 'ucode-components';
import './index.scss';

const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
@connect(
  state => {
    return {
      loading: state.domainConfigs.domainConfigsListLoading,
      enterprisesSelectionsList: state.enterprises.enterprisesSelectionsList,


      domainConfigsByEseId: state.domainConfigs.domainConfigsByEseId,


      permissionIds: state.users.permissionIds||[],
      openIds: state.users.openIds,
      addressBookDetail: state.addressBooks.addressBookDetail,
      availableCount: state.memberManage.availableCount,
    };
  },
  {
    domainConfigsByCodeBank: domainConfigsByCodeBank.REQUEST,
    loadEnterprisesSelections: loadEnterprisesSelections.REQUEST,


    deleteMemberShip: deleteMemberShip.REQUEST,
    exportMemberShip: exportMemberShip.REQUEST,
    // loadAddressBookById: loadAddressBookById.REQUEST,
    exportAvailable: exportAvailable.REQUEST,

    setNavBar,
  }
)
class codeBankList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createdTimeSort: null,
      updatedTimeSort: null,
      dataId: '',
      showFactoryCreateForm: false,

      showExport: {
        show: false,
        id: '',
      },


      perPageCountList: [20, 50, 100],
      params: {
        applyStart: '',
        applyEnd: '',
        id: '',
        page: 0,
        size: 20,
        sort: 'createdTime,desc'
      },

      factoryColumns: [
        {
          key: 'name',
          dataIndex: 'name',
          title: '域名名称',
        },
        {
          key: 'domain',
          dataIndex: 'domain',
          title: '域名地址',
          render: (value, record) => {
            if (record.codeSourceType == 'WECHART_CODE') {
              return <div>
                <div title={value}><span className="text-overflow">{value}</span></div>
              </div>
            }
            else {
              return <div>
                <div title={`${record.protocol}${value}/${record.subDomain}`}><span className="text-overflow">{record.protocol}{value}/{record.subDomain}</span></div>
              </div>
            }
            }
        
        },

        {
          key: 'codeSourceType',
          dataIndex: 'codeSourceType',
          title: '码类型',
          render: (value, record) => {
            let list = { CODE_IMPORT: '第三方码', PLATFORM_SHORT_CODE: '平台短码', PLATFORM_STANDARD: '平台标准', WECHART_CODE: "微信码", WRITE_OFF_CODE: '核销码' }
            return <div>
              <div title={list[value]}><span className="text-overflow">{list[value]}</span></div>
            </div>
          }
        },
        {
          key: 'queryRules',
          dataIndex: 'queryRules',
          title: '码查询规则',
         

        },
        {
          key: 'eseId',
          dataIndex: 'eseId',
          title: '使用企业',
          render: (value, record) => <div title={value ? this.props.enterprisesSelectionsList.filter(key=>key.id==value)[0]?this.props.enterprisesSelectionsList.filter(key=>key.id==value)[0].text : '': ''}>
            <span className="text-overflow">{value ?  this.props.enterprisesSelectionsList.filter(key=>key.id==value)[0]? this.props.enterprisesSelectionsList.filter(key=>key.id==value)[0].text : '': ''}</span>
          </div>

        },
        // {
        //   key: 'status',
        //   dataIndex: 'status',
        //   title: '操作',
        //   render: (value, record) => {
        //     return (
        //       <div className="table-operate-btn" style={{ color: '#006EFF' }}>
        //         {this.props.permissionIds.includes('member-center.manage.membership.update') && <span onClick={() => { this.handleAllotOut(record) }}>编辑</span>}
        //         {this.props.permissionIds.includes('member-center.manage.membership.export') &&
        //           <span onClick={() => { this.showExport(record.id) }}>导出</span>
        //         }

        //         {this.props.permissionIds.includes('member-center.manage.membership.delete') &&
        //           <span onClick={() => this.handleConfirm(record.id)}>删除
        //           {this.state.visibleId == record.id && <ConfirmPopout
        //               onCancel={this.onCancel}
        //               onOk={this.onOk}
        //               styles={{ right: '0px' }}
        //               strongText={'确定删除该分组？'}
        //               text={'分组删除后无法恢复，请谨慎操作'}
        //             />}
        //           </span>
        //         }
        //       </div>
        //     );
        //   },
        // },
      ],
    };
  }


  changeSearchBox = (e) => {
    const params = this.state.params;
    params.name = e.target.value;
    this.setState({
      params
    })
  }
  changeSearchBox2 = (value) => {
    const params = this.state.params;
    params.domainQueryType = value;
    this.setState({
      params
    })
  }
  clear = () => {
    const params = this.state.params;
    params.name = '';
    params.domainQueryType = '';
      this.setState({
        params
      })
  }
  search = () => {
    const params = this.state.params;
    params.page = 0;
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    const { name } = parsed;
    this.props.domainConfigsByCodeBank({batchname:name,...params});
  }



  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    const { name } = parsed;
    this.props.domainConfigsByCodeBank({batchname:name,...params});
  }
  onPerPageChange = (val) => {
    const { params } = this.state;
    params.size = val;
    params.page = 0;
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    const { name } = parsed;
    this.props.domainConfigsByCodeBank({batchname:name,...params});
  }



  handleConfirm = (param) => {
    const { visibleId } = this.state;
    this.setState({ visibleId: param == visibleId ? '' : param })
  }


  onCancel = () => {
    this.handleConfirm(this.state.visibleId);
  }

  onOk = () => {
    const { visibleId } = this.state;
    this.handleConfirm(visibleId);
    this.props.deleteMemberShip({
      data: { id: visibleId },
      callback: () => {
        const { location, history } = this.props;
        const parsed = queryString.parse(location.search);
        const { name } = parsed;
        this.props.domainConfigsByCodeBank({batchname:name,...this.state.params});
      }
    });
  }





  showExport = (id) => {
    this.props.exportAvailable({
      callback: () => {
        this.setState({ showExport: { show: true, id: id } });
      }
    });

  }




  // onDialogOk = () => {
  //   const { showExport } = this.state;
  //   const { addressBookDetail } = this.props;
  //   this.props.exportMemberShip({
  //     data: {
  //       id: showExport.id,
  //       email: 'guanchen.xu@sao.so'
  //     },
  //     callback: () => {

  //       this.setState({ showExport: { show: false, id: '' } });
  //     }
  //   });
  // }



  componentDidMount() {

    this.props.setNavBar('码库管理');

    const { location, history } = this.props;
    const {params}=this.state;
    const parsed = queryString.parse(location.search);
    const { name } = parsed;
    const { domainConfigsByCodeBank, loadAddressBookById, openIds } = this.props;
    domainConfigsByCodeBank({batchname:name,...params});

    this.props.loadEnterprisesSelections();
    // loadAddressBookById({ id: openIds.addressBookId });
  }





  tableHeadFilter = param => {
    const { list, titleContent, value, filterFun } = param;
    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={value}
        onFilter={value => {
          filterFun(value);
        }}
      >
        {titleContent}
      </DataTable.FilterableColumnHeader>
    );
  };
  handleSort = (curOrder, key) => {
    const newOrder = handSortOrder(curOrder);
    if (key == 'createdTime') {
      this.setState({ createdTimeSort: newOrder, updatedTimeSort: null });
    }
    else {
      this.setState({ createdTimeSort: null, updatedTimeSort: newOrder });
    }


    const { params } = this.state;
    params.sort = `${key},${newOrder}`;
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    const { name } = parsed;
    this.props.domainConfigsByCodeBank({batchname:name,...params});
  };
  // 排序 sort
  tableHeadSort = (col, type) => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state[type]}
        sortKey={col.key}
        onSort={() => { this.handleSort(this.state[type], col.key); }}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    );
  };
  tableHeadInit = () => {
    return this.state.factoryColumns.map(column => {
      if (column.key === 'createdTime') {
        return {
          ...column,
          headerRender: col => this.tableHeadSort(col, 'createdTimeSort'),
        };
      }
      else if (column.key === 'updatedTime') {
        return {
          ...column,
          headerRender: col => this.tableHeadSort(col, 'updatedTimeSort'),
        };
      }

      // else if (column.key === 'factoryType') {
      //   return {
      //     ...column,
      //     headerRender: () => {
      //       const { domainConfigsByEseId, factoryPageListParam } = this.props;
      //       return this.tableHeadFilter({
      //         list: factoryTypeFilter,
      //         titleContent: '来源类型',
      //         value: factoryPageListParam[column.key] ? factoryPageListParam[column.key].split(',') : [],
      //         filterFun: value => {
      //           const newValue = value.join(',');
      //           domainConfigsByEseId({ factoryPageListParam: { ...factoryPageListParam, [column.key]: newValue, page: 0, }, });
      //         },
      //       });
      //     },
      //   };
      // } 
      else {
        return column;
      }
    });
  };

  handleAllotOut = (record) => {
    if (record) {
      this.props.history.push(`/membercentermanage/edit?type=edit&id=${record.id}`);

    }
    else {
      this.props.history.push(`/membercentermanage/create`);
    }

  }


  render() {
    const { perPageCountList, params } = this.state;
    const { domainConfigsByEseId = [], addressBookDetail, loading } = this.props;
    return (
      <div className={`codeBank-container`}>

        {/* top-bar */}
        <div className="factory-topbar">
          {/* {this.props.permissionIds.includes('member-center.manage.membership.create') && */}
            {/* <Button onClick={() => { this.handleAllotOut() }}>新增码库</Button> */}
          {/* } */}

          {/* <div className="factory-search-input"
            style={{ marginRight: '10px' }}
          >
            <SearchInput
              value={params.name}
              placeholder="输入域名地址搜索"
              onChange={this.changeSearchBox}
              style={{ width: 180 }}
              onSearch={this.search}
            />
          </div> */}


          <Button onClick={this.clear} className="weak-button" style={{ float: 'right', marginRight: 0 }}>清空</Button>
          <Button onClick={this.search} style={{ float: 'right', marginRight: 10 }}>搜索</Button>
          <div className="factory-search-input"
            style={{ marginRight: '10px' }}
          >
            <Input
              value={params.name}
              placeholder="输入域名地址搜索"
              onChange={this.changeSearchBox}
              style={{ width: 180 }}
            // onSearch={value => { getMemberInfoList({ factoryPageListParam: { ...factoryPageListParam, name: value, page: 0, size: 20, }, }); }}
            />
          </div>

          <div className="factory-search-input"
            style={{ marginRight: '10px', width: 'auto' }}
          >
            <NewSelect
            style={{width:180}}
              placeholder={'选择域名地址类型'}
              value={params.domainQueryType}
              list={Object.keys(enums.domainQueryType).map(item => { return { value: item, text: enums.domainQueryType[item].name } })}
              onChange={this.changeSearchBox2}
            />
          </div>








        </div>
        {/* 列表，分页 */}
        <DataTable
          columns={this.tableHeadInit()}
          data={domainConfigsByEseId.content}
          rowKey="id"
          // rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
          loading={loading}

          messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) {
              return (
                <div className="text-weak">暂无数据</div>
              );
            }
          }}
        />
        {(domainConfigsByEseId.content ? domainConfigsByEseId.content.length > 0 : false) &&
          <Pager
            total={domainConfigsByEseId.totalElements}
            current={params.page}
            perPageCountList={perPageCountList}
            perPageCount={params.size}
            onPageChange={this.onPageChange}
            onPerPageChange={this.onPerPageChange}
          />}

      </div>
    );
  }
}

export default codeBankList;
